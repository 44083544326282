import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../../helpers/constants";
import PG_JASB_HOME_INTRO_CONTENT from "../../../jp/amazon-sellers-bundle/HomeIntro/index.content";
import JpStandardFormBlockContainer from "../../../../containers/common/forms/JpStandardFormBlockContainer";
import HomeIntroScreen from "./HomeIntroScreen";
import "./index.scss";

const HomeIntro = ({
  isSecondStateCollectEmail,
  setIsSecondStateCollectEmail,
  onSuccess,
}) => {
  const { pictureScreen, PointIcon } = PG_JASB_HOME_INTRO_CONTENT;

  return (
    <section className="PgJASB-HomeIntro">
      <div className="container">
        <div className="PgJASB-HomeIntro-row">
          <div className="PgJASB-HomeIntro-info">
            <h2 className="PgJASB-HomeIntro__h1">
              <span>AMZScoutによる完全なAmazonツールセットで</span>
              販売を開始し
              <br />
              ビジネスを成長させる
            </h2>
            <div className="PgJASB-HomeIntro-points">
              <div className="PgJASB-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>アマゾンで販売する全部のステップを学ぶ</div>
              </div>
              <div className="PgJASB-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>利益性が高い商品を見つける</div>
              </div>
              <div className="PgJASB-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  AMZScoutウェブアプリツールを使用して、適当な商品リストと有料広告の適切なキーワードで販売する
                </div>
              </div>
            </div>
            <JpStandardFormBlockContainer
              pageFormState={isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              buttonTextFirstState="AMZScoutを無料でお試しください！"
              buttonTextSecondState="AMZScoutを無料でお試しください！"
              page={PAGES.JP_AMAZON_SELLERS_BUNDLE}
              customClass="PgJASB-HomeIntro-emailCollect"
              placeholder="メールアドレスを入力して、無料トライアルを開始してください。"
              onSuccess={onSuccess}
              isIntro
            />
          </div>
          <HomeIntroScreen
            customClass="PgJASB-HomeIntro-screen"
            pictureScreen={pictureScreen}
          />
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default HomeIntro;
